<template lang="pug">
  .introduction(:data-key="data.id")
    prismic-rich-text.title(
      v-if="data.title"
      :field="data.title"
    )
    prismic-rich-text.subtitle(
      v-if="data.subtitle"
      :field="data.subtitle"
    )
    prismic-image.avatar(
      v-if="data.avatar"
      :field="data.avatar"
    )
    prismic-rich-text.text(
      v-if="data.text"
      :field="data.text"
    )
    prismic-rich-text.caption(
      v-if="data.caption"
      :field="data.caption"
    )
</template>

<script>
export default {
  name: 'Introduction',
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>
