<template lang="pug">
  .frontpage
    p.year {{ data.year }}
    prismic-rich-text.title(
      v-if="data.title"
      :field="data.title"
    )
    p.issue-and-year {{ data.issue_and_year }}
    .image-wrapper
      prismic-image.image(
        v-if="data.image"
        :field="data.image"
      )
</template>

<script>
export default {
  name: 'Frontpage',
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>
