<template lang="pug">
  .nav(:class="navClassName")
    .nav-content
      p.heading(v-if="!isTopType") Efnisyfirlit
      prismic-rich-text.heading(
        v-if="!isMobileView && isTopType"
        :field="data.info.title"
      )
      ul.nav-items#nav-items(
        v-dragscroll.x="!isTopType"
      )
        li.item(
          v-for="item in navData"
          :data-key="item.id"
          @mousedown="goToPage($event, item.uid)"
          :active="item.uid === currentRoute"
          :class="{'item--with-playbar': !isTopType && item.contentType !== 'standard'}"
        )
          .playbar#playbar(
            v-if="!isTopType && item.contentType !== 'standard'"
          )
            .progressbar#progressbar
          .image-container(
            v-if="!isTopType && item.contentType === 'standard'"
          )
            prismic-image.image(:field="item.image")
          prismic-rich-text.title(
            v-if="item.title && typeof item.title !== 'string'"
            :field="item.title"
          )
          .title(
            v-else-if="item.title && typeof item.title === 'string'"
          )
            h2 {{ item.title }}
      .contact-block(v-if="!isMobileView && isTopType")
        prismic-rich-text.contact-block-info(
          :field="data.info.contact_info"
        )
        .contact-block-social
          .social-item(
            v-for="item in data.info.social"
          )
            prismic-link.social-item-link(:field="item.link")
              prismic-image.social-item-icon(:field="item.image")
</template>

<script>
import { goToPage } from '../utils/change-view'
import { isObjectEmpty } from '../utils/helpers'

export default {
  name: 'Nav',
  props: {
    data: {
      type: [Array, Object],
      default () {
        return []
      }
    },
    navType: {
      type: String,
      default: 'top'
    },
    isMobileView: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    if (!this.isTopType) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.attributeName === 'active') {
            if (mutation.target.hasAttribute('active')) {
              this.scrollToActiveNavItem()
            } else if (mutation.target.classList.contains('item--with-playbar')) {
              mutation.target.removeAttribute('playing')
              mutation.target.querySelector('#progressbar').style.width = ''
            }
          }
        })
      })
      const navItemsContainer = this.$el.querySelector('#nav-items')
      navItemsContainer.children.forEach((item) => {
        observer.observe(item, {
          attributes: true
        })
      })
      this.scrollToActiveNavItem()
    } else {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.attributeName === 'active' && mutation.target.hasAttribute('active')) {
            this.scrollToActiveNavItem()
          }
        })
      })
      observer.observe(this.$el, {
        attributes: true
      })
    }
  },
  computed: {
    currentRoute () {
      if (isObjectEmpty(this.$route.params)) {
        return this.$route.path
      }
      return this.$route.params.uid
    },
    isTopType () {
      return this.navType === 'top'
    },
    navData () {
      return this.isTopType ? this.data.items : this.data
    },
    navClassName () {
      const defaultClassName = 'nav'
      return `${defaultClassName}--${this.navType}-type`
    }
  },
  methods: {
    goToPage (e, uid) {
      goToPage.apply(this, [e, uid])
    },
    scrollToActiveNavItem () {
      const navItemsContainer = this.$el.querySelector('#nav-items')
      const activeNavItem = navItemsContainer.querySelector('[active]')
      if (!activeNavItem) {
        return
      }
      setTimeout(() => {
        activeNavItem.scrollIntoView({
          behavior: 'smooth',
          block: this.isTopType ? 'start' : 'end',
          inline: this.isTopType ? 'nearest' : 'start'
        })
      }, 0)
    }
  }
}
</script>
