<template lang="pug">
  .not-found
    .message
      h2.message__number 404
      p.message__text Sorry! The page you’re looking for was not found. You may have mistyped the address or the page may have moved.
      a.message__link(@click="goToFrontpage") Take me to the home page
</template>

<script>
import { changePage } from '../utils/change-view'

export default {
  name: 'NotFound',
  methods: {
    goToFrontpage () {
      changePage.apply(this, ['/'])
    }
  }
}
</script>
