<template lang="pug">
  .magazine-container
    .loading-screen(v-if="!isDataFetched")
    .magazine(v-if="isDataFetched")
      button.backward#backward(@click="backward" :visible="previuosRoute")
        img.icon(src="@/assets/arrow-previous.svg")
      button.forward#forward(@click="forward" :visible="nextRoute")
        img.icon(src="@/assets/arrow-next.svg")
      Nav(
        v-if="showHeaderNav"
        :data="headerNavData"
        :nav-type="'header'"
        :class="{'nav--decreased': isHeaderNavSizeDecreased}"
      )
      Nav(
        :active="isTopNavVisible"
        :data="topNavData"
        :nav-type="'top'"
        :is-mobile-view="isMobileView"
      )
      .nav-button-container
        button.nav-button(
          :class="{'nav-open': isTopNavVisible}"
          @click="isTopNavVisible = !isTopNavVisible"
        )
          .line(v-for="n in 3")
        p.nav-button-caption Efnisyfirlit
      .magazine__content#pages(
        v-touch:swipe.left="forward"
        v-touch:swipe.right="backward"
      )
        component(
          class="fade-in-up"
          :is="activePage.type"
          :data="activePage.data"
          :is-mobile-view="isMobileView"
        )
      Cookies
</template>

<script>
import Nav from './Nav'
import Frontpage from './Frontpage'
import Introduction from './Introduction'
import Article from './Article'
import Sponsors from './Sponsors'
import Cookies from './Cookies'
import NotFound from './NotFound'

import fetchContent from '../utils/prismic'
import { changePage } from '../utils/change-view'
import {
  screenSizeQualifier,
  navSizeController
} from '../utils/helpers'

export default {
  name: 'Magazine',
  components: {
    Nav,
    Frontpage,
    Introduction,
    Article,
    Sponsors,
    Cookies,
    NotFound
  },
  data () {
    return {
      isDataFetched: false,
      pagesData: [],
      notFoundData: {
        type: 'NotFound'
      },
      headerNavData: [],
      topNavData: {},
      isTopNavVisible: false,
      isMobileView: null,
      isHeaderNavSizeDecreased: false
    }
  },
  created () {
    window.addEventListener('resize', screenSizeQualifier.bind(this))
    window.addEventListener('scroll', navSizeController.bind(this))
    window.addEventListener('keydown', this.arrowKeyPressed)
    screenSizeQualifier.call(this)
    fetchContent.call(this)
    this.$parent.$on('logoClicked', () => changePage.apply(this, ['/']))
  },
  computed: {
    activeRouteUID () {
      return this.$route.params.uid || this.$route.path
    },
    activePage () {
      if (this.activeRouteUID === 'preview' && this.$route.query.documentId) {
        const foundPreviewPage = this.pagesData.find((page) => page.id === this.$route.query.documentId)
        if (foundPreviewPage) {
          changePage.apply(this, [foundPreviewPage.uid])
        }
      }
      const foundPage = this.pagesData.find((page) => page.uid === this.activeRouteUID)
      if (foundPage) {
        return foundPage
      }
      return this.notFoundData
    },
    previuosRoute () {
      const activePageRouteIndex = this.pagesData.indexOf(this.activePage)
      const previousPage = this.pagesData[activePageRouteIndex - 1]
      if (!previousPage || activePageRouteIndex === -1) {
        return false
      }
      return previousPage.uid
    },
    nextRoute () {
      const activePageRouteIndex = this.pagesData.indexOf(this.activePage)
      const nextPage = this.pagesData[activePageRouteIndex + 1]
      if (!nextPage || activePageRouteIndex === -1) {
        return false
      }
      return nextPage.uid
    },
    isFrontpage () {
      return this.activePage.type === 'Frontpage'
    },
    isNotFound () {
      return this.activePage.type === 'NotFound'
    },
    showHeaderNav () {
      return !this.isMobileView && !this.isFrontpage && !this.isNotFound
    }
  },
  methods: {
    backward () {
      changePage.apply(this, [this.previuosRoute])
    },
    forward () {
      changePage.apply(this, [this.nextRoute])
    },
    arrowKeyPressed (e) {
      if (e.keyCode === 37) {
        this.backward()
      }
      if (e.keyCode === 39) {
        this.forward()
      }
    }
  },
  watch: {
    activePage () {
      this.isTopNavVisible = false
    },
    isMobileView (newValue) {
      this.isTopNavVisible = false
      if (!newValue) {
        document.body.removeAttribute('unscrollable')
      }
    },
    isTopNavVisible (newValue) {
      if (this.isMobileView && newValue) {
        document.body.setAttribute('unscrollable', '')
        return
      }
      document.body.removeAttribute('unscrollable')
    }
  }
}
</script>
