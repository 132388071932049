import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import VueMasonry from 'vue-masonry-css'
import PrismicVue from '@prismicio/vue'
import linkResolver from './link-resolver'
import VueDragscroll from 'vue-dragscroll'
import Vue2TouchEvents from 'vue2-touch-events'
// eslint-disable-next-line
import styles from './styles/main.scss'
import router from './router/index'

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(PrismicVue, {
  endpoint: 'https://ljosid-magazine.cdn.prismic.io/api/v2',
  linkResolver
})
Vue.use(VueMasonry)
Vue.use(VueDragscroll)
Vue.use(Vue2TouchEvents)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
