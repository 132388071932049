<template lang="pug">
  .sponsors(:data-key="data.id")
    prismic-rich-text.sponsor_title(
      v-if="data.sponsor_title"
      :field="data.sponsor_title"
    )
    masonry(
      :cols="{default: 3, 1200: 2, 768: 1}"
      :gutter="{default: '30px', 700: '15px'}"
    )
      .sponsor_list(
        v-for="(sponsor_list, index) in data.sponsor_lists"
        :key="index"
      )
        prismic-rich-text.sponsor_list_title(
          v-if="sponsor_list.sponsor_list_title"
          :field="sponsor_list.sponsor_list_title"
        )
        prismic-rich-text.sponsor_list_text(
          v-if="sponsor_list.sponsor_list_text"
          :field="sponsor_list.sponsor_list_text"
        )
</template>

<script>

export default {
  name: 'Sponsors',
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>
