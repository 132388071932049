<template lang="pug">
  .article
    prismic-rich-text.title(
      :field="data.title"
    )
    #intro-video.intro-video(v-if="data.intro_video.url && defineVideo(data.intro_video.url)")
      .video-wrapper
        video(controls)
          source(:src="data.intro_video.url")
    .author
      prismic-image.image(
        v-if="data.author_avatar && !isObjectEmpty(data.author_avatar)"
        :field="data.author_avatar"
      )
      p.name(v-if="data.author_name") {{ data.author_name }}
    prismic-rich-text.intro(
      :field="data.intro"
    )
    .video(v-if="data.contentType === 'video'")
      .video-wrapper
        video(
          controls
          @timeupdate="mediaProgressUpdated"
          @play="mediaPlayStarted"
          @pause="mediaPaused"
        )
          source(:src="data.video_podcast.url")
    .podcast(v-if="data.contentType === 'podcast'")
      .podcast-wrapper
        audio(
          controls
          @timeupdate="mediaProgressUpdated"
          @play="mediaPlayStarted"
          @pause="mediaPaused"
        )
          source(:src="data.video_podcast.url")
    .body#body
      .item#body-item(
        v-for="bodyElem in data.body"
      )
        prismic-rich-text.text(
          v-if="bodyElem.slice_type === 'text'"
          :field="bodyElem.primary.text"
        )
        .image-block(
          v-if="bodyElem.slice_type === 'image'"
          :class="{'with-background': bodyElem.primary.with_background}"
        )
          .image-wrapper
            prismic-image.image(
              :field="bodyElem.primary.img"
            )
          prismic-rich-text.caption(
            :field="bodyElem.primary.caption"
          )
        .quote(v-if="bodyElem.slice_type === 'quote'")
          p {{ bodyElem.primary.text }}
          img(src='@/assets/quote-icon.svg')
        .companies(v-if="bodyElem.slice_type === 'companies'")
          .company(v-for="item in bodyElem.items")
            prismic-link.logotype(:field="item.hyperlink")
              prismic-image.image(
                :field="item.logo"
              )
            p.name {{ item.name }}
        .image-tile(
          v-if="bodyElem.slice_type === 'image_tile'"
          :class="{'image-tile--complete': isObjectComplete(bodyElem.primary)}"
        )
          .image-tile-container
            .image-wrapper(
              v-for="item in bodyElem.primary"
              v-if="!isObjectEmpty(item)"
            )
              prismic-image.image(:field="item")
        .ad(v-if="bodyElem.slice_type === 'ad'")
          .ad-item(
            v-for="item in bodyElem.items"
            :class="`ad-item--${item.type}-type`"
            :style="`background-image:url(${item.image.url})`"
          )
            prismic-link.link(:field="item.link")
</template>

<script>
import {
  isObjectEmpty,
  isObjectComplete,
  defineVideo
} from '../utils/helpers'
import {
  mediaProgressUpdated,
  mediaPlayStarted,
  mediaPaused
} from '../utils/media-controller'

export default {
  name: 'Article',
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    },
    isMobileView: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.startIntroVideo()
  },
  updated () {
    this.$el.style.visibility = 'hidden'
    this.$el.classList.remove('fade-in-up')
    setTimeout(() => {
      this.$el.classList.add('fade-in-up')
      this.$el.style.visibility = ''
    }, 0)
    this.startIntroVideo()
  },
  methods: {
    isObjectEmpty,
    isObjectComplete,
    defineVideo,
    mediaProgressUpdated (e) {
      if (!this.isMobileView) {
        mediaProgressUpdated.apply(this, [e])
      }
    },
    mediaPlayStarted () {
      if (!this.isMobileView) {
        mediaPlayStarted.call(this)
      }
    },
    mediaPaused () {
      if (!this.isMobileView) {
        mediaPaused.call(this)
      }
    },
    startIntroVideo () {
      const introVideoContainer = this.$el.querySelector('#intro-video')
      if (!introVideoContainer) {
        return
      }
      const introVideo = introVideoContainer.querySelector('video')
      const introVideoPromise = introVideo.play()
      if (introVideoPromise) {
        introVideoPromise
          .catch(() => {
            introVideo.muted = true
            introVideo.play()
          })
      }
    }
  }
}
</script>
