export function mediaProgressUpdated (e) {
  const navContainer = this.$parent.$el.querySelector('#nav-items')
  if (!navContainer) {
    return
  }
  const targetNavItem = navContainer.querySelector(`[data-key=${this.data.id}]`)
  if (!targetNavItem) {
    return
  }
  const playbar = targetNavItem.querySelector('#playbar')
  const progressbar = playbar.querySelector('#progressbar')
  progressbar.style.width = `${e.currentTarget.currentTime / e.currentTarget.duration * 100}%`
}

export function mediaPlayStarted () {
  const navContainer = this.$parent.$el.querySelector('#nav-items')
  if (!navContainer) {
    return
  }
  const targetNavItem = navContainer.querySelector(`[data-key=${this.data.id}]`)
  if (!targetNavItem) {
    return
  }
  targetNavItem.setAttribute('playing', '')
}

export function mediaPaused () {
  const navContainer = this.$parent.$el.querySelector('#nav-items')
  if (!navContainer) {
    return
  }
  const targetNavItem = navContainer.querySelector(`[data-key=${this.data.id}]`)
  if (!targetNavItem) {
    return
  }
  targetNavItem.removeAttribute('playing')
}
