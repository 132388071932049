export function isObjectEmpty (object) {
  return object && Object.keys(object).length === 0 && object.constructor === Object
}

export function isObjectComplete (object) {
  return !isObjectEmpty(object) && Object.values(object).every(item => !isObjectEmpty(item))
}

export function screenSizeQualifier () {
  let isMobileView = false
  if (window.innerWidth < 768) {
    isMobileView = true
  }
  if (this.isMobileView !== isMobileView) {
    this.isMobileView = isMobileView
  }
}

export function navSizeController () {
  if (document.documentElement.scrollTop > 0) {
    if (!this.isHeaderNavSizeDecreased) {
      this.isHeaderNavSizeDecreased = true
    }
    return
  }
  if (this.isHeaderNavSizeDecreased) {
    this.isHeaderNavSizeDecreased = false
  }
}

export function defineVideo (url) {
  return !!url.match(/\.(3g2|3gp|aaf|asf|avchd|avi|drc|flv|m2v|m4p|m4v|mkv|mng|mov|mp2|mp4|mpe|mpeg|mpg|mpv|mxf|nsv|ogg|ogv|ogm|qt|rm|rmvb|roq|srt|svi|vob|webm|wmv|yuv)/)
}

export function defineAudio (url) {
  return !!url.match(/\.(aac|aiff|ape|au|flac|gsm|it|m3u|m4a|mid|mod|mp3|mpa|pls|ra|s3m|sid|wav|wma|xm)/)
}
