import { defineVideo, defineAudio } from './helpers'

export default async function fetchContent () {
  const pagesData = []
  const headerNavData = []
  const topNavData = {
    info: {},
    items: []
  }
  await this.$prismic.client.getSingle('frontpage')
    .then((document) => {
      const pageData = {}
      pageData.data = {
        title: document.data.title,
        year: document.data.year,
        issue_and_year: document.data.issue_and_year,
        image: document.data.image
      }
      pageData.type = document.type.charAt(0).toUpperCase() + document.type.slice(1)
      pageData.uid = '/'
      pageData.id = document.id
      pagesData.push(pageData)
      headerNavData.push({
        uid: '/',
        title: document.data.nav_title,
        image: document.data.nav_image,
        contentType: 'standard'
      })
      topNavData.items.push({
        uid: '/',
        title: document.data.nav_title
      })
    })
    .catch((err) => console.error(err))
  await this.$prismic.client.getSingle('introduction')
    .then((document) => {
      const pageData = {}
      pageData.data = {
        title: document.data.title,
        subtitle: document.data.subtitle,
        avatar: document.data.avatar,
        text: document.data.text,
        caption: document.data.caption
      }
      pageData.type = document.type.charAt(0).toUpperCase() + document.type.slice(1)
      pageData.uid = document.uid
      pageData.id = document.id
      pagesData.push(pageData)
      headerNavData.push({
        uid: document.uid,
        title: document.data.nav_title,
        image: document.data.nav_image,
        contentType: 'standard'
      })
      topNavData.items.push({
        uid: document.uid,
        title: document.data.nav_title
      })
    })
    .catch((err) => console.error(err))
  await this.$prismic.client.getSingle('articles')
    .then(async (document) => {
      for (const bodyElem of document.data.body) {
        if (bodyElem.slice_type === 'article' && bodyElem.primary.article.id && !bodyElem.primary.article.isBroken) {
          await this.$prismic.client.getByID(bodyElem.primary.article.id)
            .then((document) => {
              const pageData = {}
              let contentType = 'standard'
              const videoOrPodcast = document.data.video_podcast
              if (videoOrPodcast.url) {
                if (defineVideo(videoOrPodcast.url)) {
                  contentType = 'video'
                } else if (defineAudio(videoOrPodcast.url)) {
                  contentType = 'podcast'
                }
              }
              pageData.data = {
                id: document.id,
                title: document.data.title,
                intro_video: document.data.intro_video,
                author_avatar: document.data.author_avatar,
                author_name: document.data.author_name,
                intro: document.data.intro,
                body: document.data.body,
                video_podcast: document.data.video_podcast,
                contentType
              }
              pageData.type = document.type.charAt(0).toUpperCase() + document.type.slice(1)
              pageData.uid = document.uid
              pageData.id = document.id
              pagesData.push(pageData)
              const headerNavItemData = {
                uid: document.uid,
                title: document.data.title,
                image: document.data.nav_image,
                contentType
              }
              if (contentType !== 'standard') {
                headerNavItemData.id = document.id
              }
              headerNavData.push(headerNavItemData)
              topNavData.items.push({
                uid: document.uid,
                title: document.data.title
              })
            })
            .catch((err) => console.error(err))
        }
      }
    })
    .catch((err) => console.error(err))
  await this.$prismic.client.getSingle('top_menu')
    .then((document) => {
      topNavData.info = document.data
    })
    .catch((err) => console.error(err))
  await this.$prismic.client.getSingle('sponsors')
    .then((document) => {
      const pageData = {}
      pageData.data = {
        sponsor_title: document.data.sponsor_title,
        sponsor_lists: document.data.sponsor_lists
      }
      pageData.type = document.type.charAt(0).toUpperCase() + document.type.slice(1)
      pageData.uid = document.uid
      pageData.id = document.id
      pagesData.push(pageData)
      headerNavData.push({
        uid: document.uid,
        title: document.data.nav_title,
        image: document.data.nav_image,
        contentType: 'standard'
      })
      topNavData.items.push({
        uid: document.uid,
        title: document.data.nav_title
      })
    })
    .catch((err) => console.error(err))
  this.headerNavData = headerNavData
  this.topNavData = topNavData
  this.pagesData = pagesData
  this.isDataFetched = true
}
