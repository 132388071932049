<template lang="pug">
  .cookies(v-if="cookiesVisible && !isConfirmed")
    button.close-button(@click="hide")
      img.icon(src="@/assets/x.svg")
    p.message Ljósið notar vafrakökur til að tryggja góða virkni og gera upplifun notenda
      | af vefnum okkar sem ánægjulegasta. <a href="https://ljosid.is/um-ljosid/um-vafrakokur/" target="_blank">Lesa meira</a>.
    button.confirm-button(@click="confirm") Samþykkja
</template>

<script>
export default {
  name: 'Cookies',
  data () {
    return {
      cookiesVisible: true
    }
  },
  computed: {
    isConfirmed () {
      return localStorage.cookies === '1'
    }
  },
  methods: {
    hide () {
      this.$el.setAttribute('hidden', '')
      setTimeout(() => {
        this.cookiesVisible = false
      }, 200)
    },
    confirm () {
      this.$el.setAttribute('hidden', '')
      setTimeout(() => {
        localStorage.cookies = '1'
      }, 200)
    }
  }
}
</script>
