export function changePage (uid) {
  if (this.$route.params.uid === uid || this.$route.path === uid || !uid) {
    return
  }
  window.scrollTo({ top: 0 })
  this.$router.push(uid)
}

export function goToPage (e, uid) {
  let wasCursorMovement = false
  const currentNavItem = e.currentTarget
  function detectCursorMovement () {
    wasCursorMovement = true
    currentNavItem.removeEventListener('mousemove', detectCursorMovement)
  }
  function detectActionEnd () {
    if (!wasCursorMovement) {
      bindedEmitChangePageEvent()
    }
    currentNavItem.removeEventListener('mousemove', detectCursorMovement)
    currentNavItem.removeEventListener('mouseup', detectActionEnd)
    currentNavItem.removeEventListener('mouseleave', detectActionEnd)
  }
  function emitChangePageEvent () {
    changePage.apply(this, [uid])
  }
  const bindedEmitChangePageEvent = emitChangePageEvent.bind(this)
  currentNavItem.addEventListener('mousemove', detectCursorMovement)
  currentNavItem.addEventListener('mouseup', detectActionEnd)
  currentNavItem.addEventListener('mouseleave', detectActionEnd)
}
