<template lang="pug">
  layout
    Magazine
</template>

<script>
import layout from './layouts/layout.vue'
import Magazine from './components/Magazine.vue'

export default {
  name: 'App',
  components: {
    layout,
    Magazine
  }
}
</script>
